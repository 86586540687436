const networkMonitor = {
  activeRequests: 0,
  lastPing: new Date().getTime(),
  lastInteraction: new Date().getTime(),
  lastSpeedTest: 0,
  failedPings: 0,
  currentMessage: "",
  logNetworkRequests: true,
  enableNetworkPing: true,
  enableDomainLevelAuth: true,
  ignoreOtherNetworkRequests: false,
  pingInterval: 5 * 60 * 1000, // 5 minutes
  sessionTimeout: 30 * 60 * 1000, // 30 minutes
  timeoutWarningAt: 5 * 60 * 1000, // 5 minutes left
  timeoutWarningText:
    "You will be automatically signed out in ${timeRemaining} due to inactivity.",
  timeoutText:
    "You have been automatically signed out due to inactivity. Please reload the page to sign back in.",
  enableSpeedTest: true,
  speedTestInterval: 2 * 60 * 1000, // 2 minutes
  speedTestIntervalWhenUnstable: 2 * 60 * 1000, // 2 minutes
  speedTestDownload: "https://cdn.regis-co.com/common/speedtest.jpg",
  expectedSpeedTestSize: 936.42 * 8, // kb
  minimumNetworkSpeed: 5000, // kbps
  slowConnectionMessage:
    "Your connection to the internet may be slow or unstable. You may experience unexpected errors in this simulation."
};

const containerStyles = {
  position: "fixed",
  display: "flex",
  flexWrap: "wrap",
  gap: "10px",
  left: "0",
  bottom: "0",
  padding: "10px",
  zIndex: "9999999999"
};

const toastStyles = {
  display: "none",
  minWidth: "400px",
  margin: "0",
  fontFamily: "Helvetica, serif",
  fontSize: "12px",
  padding: "10px",
  opacity: "1",
  borderRadius: "5px",
  color: "#333A4A",
  background: "#fff4e5",
  gap: "10px",
  alignItems: "center",
  whiteSpace: "nowrap",
  boxShadow:
    "0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%)"
};

const toastHide = {
  display: "none"
};

const toastShow = {
  display: "flex"
};

const modalStyles = {
  position: "fixed",
  left: "0",
  bottom: "0",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  fontFamily: "Helvetica, sans-serif",
  fontSize: "12px",
  color: "#343A49",
  inset: "0px",
  zIndex: "9999999",
  backgroundColor: "rgba(0, 0, 0, 0.5)"
};

const startNetworkMonitor = async options => {
  Object.assign(networkMonitor, options);

  if (networkMonitor.created) {
    return;
  }
  networkMonitor.created = true;

  Object.defineProperty(window, "NETWORK_MONITOR", {
    set: function (other) {
      alert("Can't set multiple network monitors!");
    },
    get: function () {
      return networkMonitor;
    },
    configurable: true
  });

  if (networkMonitor.logNetworkRequests !== false) {
    addNetworkListeners();
    addFetchListeners();
  }

  if (networkMonitor.enableNetworkPing) {
    window.document.addEventListener("mousedown", triggerInteraction, true);
    window.document.addEventListener("click", triggerInteraction, true);
    window.document.addEventListener("keydown", triggerInteraction, true);

    // Listen for interactions in child windows
    window.addEventListener("message", function (event) {
      try {
        const data = JSON.parse(event.data);
        const { action } = data;
        if (action === "triggerInteraction") {
          triggerInteraction();
        }
      } catch (_) {
        // An error here means the data isn't JSON. We don't care about messages we can't read.
      }
    });

    setInterval(checkForTimeout, 500);
  }

  await new Promise(resolve => setInterval(resolve, 100)); // delay to allow document.body to exist

  if (networkMonitor.enableDomainLevelAuth) {
    try {
      const child = document.createElement("iframe");
      Object.assign(child.style, {
        position: "fixed",
        left: "-1000px",
        top: "-1000px",
        width: "0px",
        height: "0px"
      });
      document.body.appendChild(child);
      child.src =
        "https://studio.regis-co.com/cdn/common/v2.4.0/domain-auth.html";

      networkMonitor.updateDomainField = (field, value) => {
        const childWindow = child.contentWindow;
        childWindow.postMessage(
          JSON.stringify({ action: "updatefield", field, value }),
          "*"
        );
      };

      addDomainField("lastPing", new Date().getTime());
      addDomainField("lastInteraction", new Date().getTime());
      addDomainField("lastSpeedTest", 0);
      addDomainField("failedPings", 0);
      addDomainField("isTimedout", false);

      let currentToken = window.localStorage.getItem("SIMGATE_TOKEN");
      setInterval(() => {
        if (window.localStorage.getItem("SIMGATE_TOKEN") !== currentToken);
        {
          currentToken = window.localStorage.getItem("SIMGATE_TOKEN");
          networkMonitor.updateDomainField("token", currentToken);
        }
      }, 500);

      // catch the removal of the simgate token, usually triggers by signout
      const _clear = window.localStorage.clear;
      window.localStorage.clear = (...args) => {
        const childWindow = child.contentWindow;
        childWindow.postMessage(JSON.stringify({ action: "clear" }), "*");
        _clear.apply(window.localStorage, args);
      };

      window.onmessage = e => {
        if (
          e.origin !== "https://studio.regis-co.com" &&
          typeof e.data === "string"
        ) {
          return;
        }
        try {
          const data = JSON.parse(e.data);
          if (data?.action === "updateDomainAuth") {
            networkMonitor[data.field] = data.value;
            if (
              data.field === "token" ||
              (data.field === "SIMGATE_TOKEN" &&
                window.localStorage.getItem("SIMGATE_TOKEN") !== data.value)
            ) {
              window.localStorage.setItem("SIMGATE_TOKEN", data.value);
              if (
                (currentToken?.length <= 12 ||
                  data.value?.length <= 12 ||
                  !currentToken ||
                  !data.value) &&
                data.value !== currentToken
              ) {
                // length of 7 is "Bearer "
                window.location.href = window.location.href;
              }
              currentToken = data.value;
            }
          }
        } catch (error) {
          if (error.message.indexOf("not valid JSON") < 0) {
            console.error(error);
          }
        }
      };
    } catch (e) {
      console.error(e);
    }
  }

  networkMonitor.signOut = signOut;
  networkMonitor.showModal = showModal;
};

const addDomainField = (field, defaultValue) => {
  let _value = defaultValue;
  Object.defineProperty(networkMonitor, field, {
    set: value => {
      if (typeof defaultValue === "number") {
        _value = new Number(value);
      } else if (typeof defaultValue === "boolean") {
        _value = Boolean(value) && value !== "false";
      } else {
        _value = value;
      }
      networkMonitor.updateDomainField?.(field, value);
    },
    get: () => {
      return _value;
    },
    configurable: true
  });
};

const obscurePassword = (object, depth) => {
  if (
    depth >= 4 ||
    (typeof object !== "object" && typeof object !== "string") ||
    !object
  ) {
    return;
  }

  try {
    if (typeof object === "string") {
      object = JSON.parse(object);
    }

    if (Object.hasOwnProperty.call(object, "password")) {
      object.password = "********";
      if (Object.hasOwnProperty.call(object, "passwordConfirm")) {
        object.passwordConfirm = "********";
      }
    } else {
      Object.keys(object).forEach(key => {
        obscurePassword(object[key], depth | 1);
      });
    }
  } catch (e) {
    // unknown error. How to log without creating potential infinite loop?
  }
};

const addNetworkListeners = () => {
  if (networkMonitor.hasAddedNetworkListeners) {
    return;
  }
  networkMonitor.hasAddedNetworkListeners = true;

  const _send = XMLHttpRequest.prototype.send;
  const _open = XMLHttpRequest.prototype.open;
  const _setRequestHeader = XMLHttpRequest.prototype.setRequestHeader;
  networkMonitor.actualXhrSend = _send;

  XMLHttpRequest.prototype.open = function (method, url) {
    this.logData = this.logData || {
      method: "",
      url: "",
      headers: {}
    };
    this.logData.method = method;
    this.logData.url = url;

    _open.apply(this, arguments);
  };

  XMLHttpRequest.prototype.setRequestHeader = function (name, value) {
    this.logData = this.logData || {
      method: "",
      url: "",
      headers: {}
    };
    this.logData.headers[name] = value;

    _setRequestHeader.apply(this, arguments);
  };

  XMLHttpRequest.prototype.send = function (data) {
    const log = null; //window.SIMGATE_LOGGER ? window.SIMGATE_LOGGER.log.bind(window.SIMGATE_LOGGER) : null;
    networkMonitor.activeRequests += 1;
    const logData = this.logData || {
      method: "",
      url: "",
      headers: {}
    };
    try {
      const dataCopy =
        data && typeof data === "object"
          ? JSON.parse(JSON.stringify(data))
          : data && typeof data != "string"
          ? JSON.stringify(data)
          : data;
      if (!log) {
        console.warn("Beginning network request to " + logData.url);
      } else {
        console.log(data);
        console.log(dataCopy);
        obscurePassword(dataCopy);
        log("warning", "Beginning network request to " + logData.url, {
          messageType: "network",
          body: dataCopy,
          method: logData.method,
          url: logData.url,
          requestHeaders: logData.headers
        });
      }

      this.addEventListener("error", function () {
        if (log) {
          log("error", "Failed network request to " + logData.url, {
            messageType: "network",
            body: dataCopy,
            method: logData.method,
            url: logData.url,
            requestHeaders: logData.headers
          });
        } else {
          console.error("Failed network request to " + logData.url);
        }
        networkMonitor.activeRequests -= 1;
      });
      this.addEventListener("abort", function () {
        if (log) {
          log("error", "Network request was aborted to " + logData.url, {
            messageType: "network",
            body: dataCopy,
            method: logData.method,
            url: logData.url,
            requestHeaders: logData.headers
          });
        } else {
          console.error("Network request was aborted to " + logData.url);
        }
        networkMonitor.activeRequests -= 1;
      });
      this.addEventListener("timeout", function () {
        if (log) {
          log("error", "Network request timed out from " + logData.url, {
            messageType: "network",
            body: dataCopy,
            method: logData.method,
            url: logData.url,
            requestHeaders: logData.headers
          });
        } else {
          console.error("Network request timed out from " + logData.url);
        }
        networkMonitor.activeRequests -= 1;
      });
      this.addEventListener("load", function () {
        if (this.readyState !== 4) {
          return;
        }
        networkMonitor.activeRequests -= 1;
        const responseText =
          (["", "text"].indexOf(this.responseType) >= 0 && this.responseText) ||
          "";
        const error =
          this.status < 200 ||
          this.status >= 300 ||
          (
            (["", "text"].indexOf(this.responseType) >= 0 &&
              this.responseText) ||
            ""
          )
            .toLowerCase()
            .indexOf("simgate server error") >= 0;

        console.log(this);
        if (log) {
          log(
            error ? "error" : "warning",
            "Network request completed to " + logData.url,
            {
              messageType: "network",
              body: dataCopy,
              method: logData.method,
              url: logData.url,
              requestHeaders: logData.headers,
              responseHeaders: this.getAllResponseHeaders(),
              response: responseText,
              responseStatus: this.status,
              responseStatusTest: this.statusText
            }
          );
        } else {
          console[error ? "error" : "warn"](
            "Network request completed to " + logData.url
          );
        }
      });
      _send.call(this, data);
    } catch (e) {
      console.error(e);
      networkMonitor.activeRequests -= 1;
      // unknown error. How to log without creating potential infinite loop?
    }
  };
};

const addFetchListeners = () => {
  if (networkMonitor.hasAddedFetchListeners) {
    return;
  }
  networkMonitor.hasAddedFetchListeners = true;

  const _fetch = window.fetch;
  if (!Boolean(_fetch)) {
    return;
  }

  networkMonitor.actualFetch = _fetch;

  window.fetch = async function () {
    const args = Array.prototype.slice.call(arguments);
    const log = null; //window.SIMGATE_LOGGER;
    try {
      log("warning", "Beginning fetch request to " + args[0], {
        messageType: "fetch",
        args
      });
    } catch (error) {}

    networkMonitor.activeRequests += 1;
    try {
      const response = await _fetch.apply(null, args);
      try {
        log("warning", "Fetch request completed to " + args[0], {
          messageType: "fetch",
          args,
          response
        });
      } catch (error) {}
      networkMonitor.activeRequests -= 1;
      const _json = response.json;
      const _text = response.text;
      response.json = async () => {
        const json = await _json.apply(response, arguments);
        try {
          log("warning", "Fetch request json loaded from " + args[0], {
            messageType: "fetch",
            args,
            response: json
          });
        } catch (error) {}
        return json;
      };
      response.text = async () => {
        const text = await _text.apply(response, arguments);
        try {
          log("warning", "Fetch request text loaded from " + args[0], {
            messageType: "fetch",
            args,
            response: text
          });
        } catch (error) {}
        return text;
      };
      return response;
    } catch (e) {
      try {
        log("error", "Fetch request failed to " + args[0], {
          messageType: "fetch",
          args,
          e
        });
      } catch (error) {}
      networkMonitor.activeRequests -= 1;
      throw e;
    }
  };
};

const triggerInteraction = () => {
  const time = new Date().getTime();
  if (networkMonitor.lastInteraction + 5000 <= time) {
    networkMonitor.lastInteraction = time;
    ping();
    speedTest();
  }
};

const signOut = async () => {
  try {
    window.simgate.signOut();
  } catch (e) {
    try {
      const { simgateUrl } = window.trcAppSettings;
      const response = await fetch(
        simgateUrl + "/api/simgate/security/signout",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Authorization": window.localStorage.getItem("SIMGATE_TOKEN")
          },
          body: "{}"
        }
      );
      const json = await response.json();
      console.log(json);
    } catch (e) {}
  }
  try {
    window.localStorage.clear();
    networkMonitor.updateDomainField?.("token", "Bearer ");
  } catch (e) {}
  setTimeout(() => {
    window.location.href = window.location.href;
  }, 500);
};

const shouldPing = () => {
  const time = new Date().getTime();
  return (
    Boolean(window.context) && // Don't ping until the user is logged in
    (networkMonitor.ignoreOtherNetworkRequests ||
      networkMonitor.activeRequests <= 0) &&
    networkMonitor.lastPing + networkMonitor.pingInterval <= time &&
    !networkMonitor.isTimedout
  );
};

const shouldRunSpeedTest = () => {
  const time = new Date().getTime();
  return (
    (networkMonitor.ignoreOtherNetworkRequests ||
      networkMonitor.activeRequests <= 0) &&
    (networkMonitor.lastSpeedTest + networkMonitor.speedTestInterval <= time ||
      (networkMonitor.currentMessage === "slow" &&
        networkMonitor.lastSpeedTest +
          networkMonitor.speedTestIntervalWhenUnstable <=
          time)) &&
    !networkMonitor.isTimedout
  );
};

const showModal = (title, message, label, action) => {
  let dialogElement = document.getElementById("timeout-modal");
  if (!dialogElement) {
    dialogElement = document.createElement("div");
    dialogElement.id = "timeout-modal";
    Object.assign(dialogElement.style, modalStyles);
    document.body.appendChild(dialogElement);
  }
  dialogElement.innerHTML = `
				<div style="
					height: 100%;
					outline: 0px;
					display: flex;
					-webkit-box-pack: center;
					justify-content: center;
					-webkit-box-align: center;
					align-items: center;
			">
				<div style="
					background-color: rgb(255, 255, 255);
					color: inherit;
					border-radius: 5px;
					box-shadow: rgb(0 0 0 / 20%) 0px 11px 15px -7px, rgb(0 0 0 / 14%) 0px 24px 38px 3px, rgb(0 0 0 / 12%) 0px 9px 46px 8px;
					margin: 32px;
					position: relative;
					overflow-y: auto;
					display: flex;
					flex-direction: column;
					max-height: calc(100% - 64px);
					max-width: 400px;">

				<div style="
					font-family: inherit;
					text-transform: uppercase;
					font-size: 14px;
					font-weight: bold;
					padding: 10px;">
					${title}
				</div>
				
				<div style="
					padding: 20px;
					border-top: 1px solid #80808040;
					border-bottom: 1px solid #80808040;
				">
					<p style="">${message}</p>
				</div>

				<div style="padding: 10px; display: flex; justify-content: flex-end;">
					<button 
						onclick="(${action})()" 
						style="padding: 10px 10px; 
							border-radius: 5px; 
							cursor: pointer; 
							background: #333A4A; 
							color: #FFFFFF; 
							font-weight: bold;
							border: none;
							">
							${label}
						</button>
					</div>
				</div>
			</div>`;
};

const ensureElements = () => {
  if (!networkMonitor.container) {
    networkMonitor.container = document.getElementById(
      "network-toast-container"
    );
    if (!networkMonitor.container) {
      networkMonitor.container = document.createElement("div");
      networkMonitor.container.id = "network-toast-container";
      Object.assign(networkMonitor.container.style, containerStyles);
      document.body.appendChild(networkMonitor.container);
    }
  }
  if (!networkMonitor.timeoutWarningElement) {
    networkMonitor.timeoutWarningElement = document.getElementById(
      "network-toast-timeout-container"
    );
    if (!networkMonitor.timeoutWarningElement) {
      networkMonitor.timeoutWarningElement = document.createElement("div");
      networkMonitor.timeoutWarningElement.id =
        "network-toast-timeout-container";
      Object.assign(networkMonitor.timeoutWarningElement.style, toastStyles);
      networkMonitor.timeoutWarningElement.innerHTML =
        '<div><svg width="20px" height="20px" fill="currentColor" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"></path><path d="M12.5 7H11v6l5.25 3.15.75-1.23-4.5-2.67z"></path></svg></div>' +
        '<div style="flex-grow:1"></div>' +
        '<button style="padding: 5px 10px; border-radius: 5px; cursor: pointer; background: #333A4A; border: none; color: #FFFFFF; font-weight: bold;">Stay</button>' +
        '<button style="padding: 5px 10px; border-radius: 5px; cursor: pointer; background: #333A4A40; border: none; color: inherit;font-weight: bold;">Sign Out</button>';
      networkMonitor.timeoutWarningElement.children[2].addEventListener(
        "click",
        function () {
          hideTimeoutToast();
          triggerInteraction();
        }
      );
      networkMonitor.timeoutWarningElement.children[3].addEventListener(
        "click",
        signOut
      );
      networkMonitor.container.appendChild(
        networkMonitor.timeoutWarningElement
      );
    }
  }
  if (!networkMonitor.unstableWarningElement) {
    networkMonitor.unstableWarningElement = document.getElementById(
      "network-toast-unstable-container"
    );
    if (!networkMonitor.unstableWarningElement) {
      networkMonitor.unstableWarningElement = document.createElement("div");
      networkMonitor.unstableWarningElement.id =
        "network-toast-unstable-container";
      Object.assign(networkMonitor.unstableWarningElement.style, toastStyles);
      networkMonitor.unstableWarningElement.innerHTML =
        '<div><svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" width="20px" height="20px" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24" data-inline="false" data-width="1em" data-height="1em" style="transform: rotate(360deg);padding: 0px 5px;"><path d="M23.64 7c-.45-.34-4.93-4-11.64-4c-1.32 0-2.55.14-3.69.38L18.43 13.5L23.64 7zM3.41 1.31L2 2.72l2.05 2.05C1.91 5.76.59 6.82.36 7L12 21.5l3.91-4.87l3.32 3.32l1.41-1.41L3.41 1.31z" fill="currentColor"></path></svg></div>' +
        '<div style="flex-grow: 1"><b>Your connection is unstable.</b></div>' +
        '<button style="padding: 5px 10px; border-radius: 5px; cursor: pointer; background: #333A4A40; border: none; color: inherit;font-weight: bold;">Close</button>';
      networkMonitor.unstableWarningElement.children[2].addEventListener(
        "click",
        hideUnstableToast
      );
      networkMonitor.container.appendChild(
        networkMonitor.unstableWarningElement
      );
    }
  }
};

const showTimeoutToast = message => {
  if (networkMonitor.timeoutMessageInterval) {
    return;
  }
  ensureElements();

  window.setTimeout(() => {
    Object.assign(networkMonitor.timeoutWarningElement.style, toastShow);
  }, 100);

  networkMonitor.timeoutMessageInterval = setInterval(() => {
    const time = new Date().getTime();
    const diff = time - networkMonitor.lastInteraction;
    const remaining = Math.max(networkMonitor.sessionTimeout - diff, 0);
    let minutes = Math.floor(remaining / 60000);
    let seconds = Math.floor(remaining / 1000) - minutes * 60;
    if (minutes < 0) {
      minutes = 0;
    }
    if (seconds < 0) {
      seconds = 0;
    }
    networkMonitor.timeoutWarningElement.children[1].innerHTML =
      '<div style="flex-grow:1"><b>Your session is about to time out! ' +
      (minutes < 10 ? "0" : "") +
      minutes +
      ":" +
      (seconds < 10 ? "0" : "") +
      seconds +
      "</b></div>";

    if (remaining <= 0) {
      clearInterval(networkMonitor.timeoutMessageInterval);
      signOut();
    }
  }, 1000);
};

const hideTimeoutToast = () => {
  if (!networkMonitor.timeoutWarningElement) {
    return;
  }
  if (networkMonitor.timeoutMessageInterval) {
    clearInterval(networkMonitor.timeoutMessageInterval);
    networkMonitor.timeoutMessageInterval = null;
  }
  Object.assign(networkMonitor.timeoutWarningElement.style, toastHide);
};

const showUnstableToast = () => {
  ensureElements();
  window.setTimeout(() => {
    Object.assign(networkMonitor.unstableWarningElement.style, toastShow);
  }, 100);
};

const hideUnstableToast = () => {
  if (!networkMonitor.unstableWarningElement) {
    return;
  }
  Object.assign(networkMonitor.unstableWarningElement.style, toastHide);
};

const checkForTimeout = () => {
  const time = new Date().getTime();
  const diff = time - networkMonitor.lastInteraction;
  if (
    diff >= networkMonitor.sessionTimeout - networkMonitor.timeoutWarningAt &&
    Boolean(window.context)
  ) {
    showTimeoutToast();
  } else {
    hideTimeoutToast();
  }
};

const ping = async () => {
  if (!shouldPing()) {
    return;
  }
  networkMonitor.lastPing = new Date().getTime();

  const handleError = error => {
    console.log(error);
    networkMonitor.failedPings += 1;
    if (networkMonitor.failedPings >= 3) {
      networkMonitor.isTimedout = true;
      networkMonitor.currentMessage = "lost";
      showModal(
        "Connection Lost",
        "Your connection to the server has been lost. Reload to try again.",
        "Reload",
        function () {
          window.location.href = window.location.href;
        }
      );
    } else {
      networkMonitor.lastPing = 0;
      setTimeout(ping, 10000);
    }
  };

  try {
    let result;
    if (window.simgate && window.simgate.getContext) {
      result = await window.simgate.getContext({ select: "user.*" });
    } else {
      const { simgateUrl } = window.trcAppSettings;
      const response = await fetch(
        simgateUrl + "/api/simgate/security/getcontext",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Authorization": window.localStorage.getItem("SIMGATE_TOKEN")
          },
          body: '{"select": "user.*"}'
        }
      );
      result = await response.json();
    }

    if (!result || result.error) {
      handleError(result?.error || null);
    } else if (result.context && result.context.userId <= 0) {
      handleError(result?.error || null);
    } else {
      networkMonitor.isTimedout = false;
      networkMonitor.lastPing = new Date().getTime();
    }
  } catch (error) {
    console.error(error);
    handleError(error);
  }
};

const speedTest = async () => {
  try {
    if (!shouldRunSpeedTest()) {
      return;
    }
    networkMonitor.lastSpeedTest = new Date().getTime();

    const start = new Date().getTime();
    const response = await fetch(
      networkMonitor.speedTestDownload + "?" + start
    );
    const end = new Date().getTime();
    const seconds = (end - start) / 1000;
    const speed = networkMonitor.expectedSpeedTestSize / seconds;
    console.warn(
      "Speed Test Results: " + Math.floor(speed * 100) / 100 + " kbps"
    );
    if (speed < networkMonitor.minimumNetworkSpeed) {
      console.warn("Network is too slow.");
      networkMonitor.currentMessage = "slow";
      showUnstableToast();
    } else {
      networkMonitor.currentMessage = "";
      hideUnstableToast();
    }
  } catch (e) {
    console.error(e);
    showUnstableToast();
  }
};

startNetworkMonitor(window.networkMonitorSettings || {});
